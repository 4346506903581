import { client } from "../client";

export type LeaderboardUser = {
  id: string;
  firstName: string;
  lastName: string;
  team?: {
    codeName: string;
  };
  avatarUrl: string;

  nbActivities: number;
  streak: number;
  gridLevel: number;
  xpBoostsForged: number;
  level: number;
  emojisGiven: number;
};

type GetLeaderboardResponseDTO = LeaderboardUser[];

export const getLeaderboardsAll = async () => {
  const res = await client.get<GetLeaderboardResponseDTO>("/leaderboards/all");

  return res.data;
};
