import { useNavigate } from "react-router-dom";
import { BingoCard } from "../../../data/minigames/bingo/types";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Button } from "../button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../table";

interface BingoLeaderboardProps {
  bingoCard: BingoCard;
  title?: string;
  showButton?: boolean;
  showDescription?: boolean;
}

export const BingoLeaderboard = ({
  bingoCard,
  title = "Leaderboards",
  showButton = false,
  showDescription = true,
}: BingoLeaderboardProps) => {
  const navigate = useNavigate();

  const userTeamId = useAuthStore((store) => store.teamId);

  return (
    <div className="flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4 w-full h-full">
      <Typography variant="h3">{title}</Typography>
      {showButton && (
        <Button variant="secondary" onClick={() => navigate("/bingo")}>
          Go to Bingo
        </Button>
      )}
      {showDescription && (
        <small className="text-gray-500">
          Cell: <strong>+10</strong> points. Rows and columns:{" "}
          <strong>+20</strong> points.
          <br />
          Completing the entire card gives <strong>+100</strong> points.
        </small>
      )}

      <div className="w-full h-full relative overflow-auto max-h-64">
        <Table>
          <TableHeader className="sticky top-0 bg-white z-50">
            <TableRow>
              <TableHead className="w-[20%]">Rank</TableHead>
              <TableHead className="w-[60%]">Team</TableHead>
              <TableHead className="w-[20%]">Points</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {bingoCard.leaderboard.map((item) => (
              <TableRow
                key={item.teamId}
                className={`${item.teamId === userTeamId && "bg-blue-50"}`}
              >
                <TableCell>
                  {item.standing <= 3 ? (
                    <div
                      className={`w-8 h-8 rounded-md flex items-center justify-center text-white font-bold ${
                        item.standing === 1
                          ? "bg-yellow-400"
                          : item.standing === 2
                          ? "bg-gray-400"
                          : "bg-orange-400"
                      }`}
                    >
                      {item.standing}
                    </div>
                  ) : (
                    <div className="w-8 h-8 rounded-md flex items-center justify-center text-black font-bold bg-gray-100">
                      {item.standing}
                    </div>
                  )}
                </TableCell>

                <TableCell>
                  <p>{item.teamCodeName}</p>
                </TableCell>

                <TableCell>
                  <p>{item.points}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
