import { useMediaQuery } from "@uidotdev/usehooks";
import { BingoChallenge } from "../../../data/minigames/bingo/types";
import { formatTime } from "../../../lib/date-utils";
import { getIconBySportType } from "../../../lib/sport-type-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { Card, CardContent } from "../card";

interface BingoCellProps {
  challenge: BingoChallenge;
  isRevealed: boolean;
  currentWeek: number;
}

export const BingoCell = ({
  challenge,
  isRevealed,
  currentWeek,
}: BingoCellProps) => {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const teamId = useAuthStore((store) => store.teamId);

  const teamProgress = challenge.progress.filter((p) => p.teamId === teamId);
  const totalProgress = challenge.type.includes("TOTAL_")
    ? teamProgress.reduce((sum, contributor) => sum + contributor.progress, 0)
    : Math.max(
        0,
        ...challenge.progress
          .filter((p) => p.teamId === teamId)
          .map((p) => p.progress)
      );

  const isCompleted =
    currentWeek >= challenge.week && totalProgress >= challenge.objective;

  const progressText = challenge.type.includes("DISTANCE")
    ? totalProgress / 1000
    : challenge.type.includes("DURATION")
    ? formatTime(
        totalProgress,
        totalProgress >= 3600 ? "HH:mm" : totalProgress === 0 ? "m" : "mm"
      )
    : totalProgress;

  const objectiveText = challenge.type.includes("DISTANCE")
    ? challenge.objective / 1000
    : challenge.type.includes("DURATION")
    ? formatTime(
        challenge.objective,
        challenge.objective >= 3600 ? "HH:mm" : "mm"
      )
    : challenge.objective;

  return (
    <Card
      key={challenge.id}
      className={`
        w-full h-full md:min-h-32
        flex flex-col justify-center items-center text-center rounded-md 
        ${isRevealed ? "hover:cursor-pointer" : "hover:cursor-not-allowed"}
        ${isCompleted ? "border-green-500" : "border-gray-200"}
        ${
          isRevealed
            ? "bg-white"
            : challenge.week === 2
            ? "bg-gray-100"
            : "bg-gray-200"
        }
        `}
    >
      <CardContent className="p-2">
        {isRevealed ? (
          <>
            <div className="text-2xl mb-1">
              {getIconBySportType(challenge.sport)}
              {!isSmallDevice && (
                <p className="text-xs">
                  {challenge.sport !== "other"
                    ? challenge.sport
                    : "any other sport"}
                </p>
              )}
            </div>

            {isSmallDevice ? (
              <p className="text-xs text-gray-600 text-center font-medium mb-1">
                {objectiveText}{" "}
                {challenge.type.includes("DISTANCE")
                  ? "km"
                  : challenge.type.includes("DURATION")
                  ? challenge.objective >= 3600
                    ? "hours"
                    : "minutes"
                  : challenge.type.includes("CALORIES")
                  ? "calories"
                  : "times"}
              </p>
            ) : (
              <p className="text-xs text-gray-600 text-center font-medium mb-1">
                {progressText} / {objectiveText}{" "}
                {challenge.type.includes("DISTANCE")
                  ? "km"
                  : challenge.type.includes("DURATION")
                  ? challenge.objective >= 3600
                    ? "hours"
                    : "minutes"
                  : challenge.type.includes("CALORIES")
                  ? "calories"
                  : "times"}
              </p>
            )}

            {challenge.type.includes("TOTAL") && (
              <div className="text-xs text-muted-foreground">as a team</div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-sm font-medium text-gray-500">
              Reveals <br /> Week {challenge.week}
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
