import { useEffect, useState } from "react";
import {
  getLeaderboard,
  LeaderboardTeam,
  LeaderboardUser,
} from "../../../data/home/get-leaderboard";
import dayjs from "../../../lib/dayjs";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import MonthSelector from "../month-selector";
import { ScrollArea } from "../scroll-area";
import { Tabs, TabsList, TabsTrigger } from "../tabs";
import { LeaderboardItem } from "./leaderboard-item";
import { Podium } from "./podium";

type Tab = "COMPANY" | "TEAMS" | "FRIENDS";

export const Leaderboard = () => {
  const companyId = useAuthStore((store) => store.companyId);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [type, setType] = useState<Tab>(companyId ? "COMPANY" : "FRIENDS");
  const [leaderboard, setLeaderboard] = useState<
    LeaderboardUser[] | LeaderboardTeam[]
  >([]);

  useEffect(() => {
    const loadLeaderboard = async () => {
      const startOfMonth = dayjs(selectedDate).startOf("month").startOf("day");
      const endOfMonth = dayjs(selectedDate).endOf("month").endOf("day");

      const leaderboard = await getLeaderboard({
        type,
        timeStart: startOfMonth.toDate(),
        timeEnd: endOfMonth.toDate(),
      });

      setLeaderboard(leaderboard);
    };

    loadLeaderboard();
  }, [type, selectedDate]);

  return (
    <div className="w-full flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4 h-auto">
      <Typography variant="h3" className="text-center">
        Leaderboards
      </Typography>

      <MonthSelector
        currentDate={selectedDate}
        onDateChange={setSelectedDate}
        maxDate={dayjs().toDate()}
      />

      <div className="flex flex-col gap-4">
        <Tabs value={type} onValueChange={(value) => setType(value as Tab)}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="COMPANY" disabled={!companyId}>
              Company
            </TabsTrigger>
            <TabsTrigger value="TEAMS" disabled={!companyId}>
              Teams
            </TabsTrigger>
            <TabsTrigger value="FRIENDS">Friends</TabsTrigger>
          </TabsList>
        </Tabs>

        {leaderboard.length === 0 ? (
          <div className="flex items-center justify-center py-4">
            <Typography variant="p" affects="light" className="text-sm">
              No data available.
            </Typography>
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center gap-4">
            <Podium
              first={leaderboard[0]}
              second={leaderboard[1]}
              third={leaderboard[2]}
            />

            {leaderboard.length > 3 && (
              <ScrollArea className="w-full">
                <div className="h-full flex flex-col gap-2 w-full">
                  {leaderboard.slice(3, 5).map((item, idx) => (
                    <LeaderboardItem
                      key={
                        type + "_" + "userId" in item
                          ? (item as LeaderboardUser).userId
                          : (item as LeaderboardTeam).teamId
                      }
                      idx={idx + 4}
                      item={item}
                    />
                  ))}
                </div>
              </ScrollArea>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
