import dayjs from "./dayjs";
import { getPaceFormatBySportType } from "./sport-type-utils";

export const timeUntil = (date: string) => {
  return dayjs().to(date);
};

export const formatDate = (date: string, format?: string) => {
  return dayjs(date).format(format || "DD/MM/YYYY HH:mm");
};

export const formatTime = (seconds: number, format: string) => {
  return dayjs.duration(seconds, "seconds").format(format);
};

export const formatPace = (
  durationInSeconds: number,
  distanceInKm: number,
  sportType: string
) => {
  if (durationInSeconds === 0 || distanceInKm === 0) {
    return "00:00";
  }

  const unit = getPaceFormatBySportType(sportType);

  if (unit === "KM/H") {
    const speedInKmPerHour = distanceInKm / (durationInSeconds / 3600);
    return Math.round(speedInKmPerHour);
  }

  let paceInSecondsPerUnit = 0;
  if (unit === "MIN/KM") {
    paceInSecondsPerUnit = durationInSeconds / distanceInKm;
  } else if (unit === "MIN/100M") {
    const distanceInMeters = distanceInKm * 1000;
    paceInSecondsPerUnit = (durationInSeconds / distanceInMeters) * 100;
  }

  const paceDuration = dayjs.duration(paceInSecondsPerUnit, "seconds");
  const minutes = String(paceDuration.minutes()).padStart(2, "0");
  const seconds = String(paceDuration.seconds()).padStart(2, "0");

  return `${minutes}:${seconds}`;
};

export const timeToSeconds = (timeStr: string) => {
  const parts = timeStr.split(":");

  const hours = parseInt(parts[0]) || 0;
  const minutes = parseInt(parts[1]) || 0;
  const seconds = parseInt(parts[2]) || 0;

  return hours * 3600 + minutes * 60 + seconds;
};

export const detailedFromNow = (date: Date) => {
  const now = dayjs();
  const target = dayjs(date);

  const diff = target.diff(now);

  const timeLeft = dayjs.duration(diff);

  const days = timeLeft.days();
  const hours = timeLeft.hours();

  let result = "in ";
  if (days > 0) {
    result += `${days} day${days > 1 ? "s" : ""} `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  return result.trim();
};
