// Always import this first!
import "./instrument";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthHandleRoute from "./components/auth-handle-route";
import { Toaster } from "./components/ui/sonner";
import { TooltipProvider } from "./components/ui/tooltip";
import "./index.css";
import { BingoOverview } from "./pages/BingoOverview";
import { CompanyPage } from "./pages/Company";
import { ErrorPage } from "./pages/Error";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Friends } from "./pages/Friends";
import { GridReveal } from "./pages/GridReveal";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Profile } from "./pages/Profile";
import { Register } from "./pages/Register";
import { Settings } from "./pages/Settings";
import { Sidebar } from "./pages/Sidebar";
import { StravaLinking } from "./pages/StravaLinking";
import { TrainingPlans } from "./pages/TrainingPlans";
import { XPForge } from "./pages/XPForge";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthHandleRoute>
        <Sidebar />
      </AuthHandleRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/company",
        element: <CompanyPage />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        // Shows the logged-in user's profile
        path: "/profile",
        element: <Profile />,
      },
      {
        // Shows the profile of the given userId
        path: "/profile/:userId",
        element: <Profile />,
      },
      {
        // Shows the logged-in user's friends
        path: "/friends",
        element: <Friends />,
      },
      {
        // Shows the friends of the given userId
        path: "/friends/:userId",
        element: <Friends />,
      },
      // Shows the logged-in user's grid reveal
      {
        path: "/grid-reveal",
        element: <GridReveal />,
      },
      // Shows the grid reveal of the given userId
      {
        path: "/grid-reveal/:userId",
        element: <GridReveal />,
      },
      {
        path: "/xp-forge",
        element: <XPForge />,
      },
      {
        path: "/training-plans",
        element: <TrainingPlans />,
      },
      { path: "/bingo", element: <BingoOverview /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/auth/strava/*",
    element: <StravaLinking />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <TooltipProvider>
      <RouterProvider router={router} />
    </TooltipProvider>
    <Toaster duration={3000} position="top-center" closeButton={true} />
    <Analytics />
    <SpeedInsights />
  </React.StrictMode>
);
