import { XPBoost } from "../data/activities/get-xp-boosts";
import { Emoji } from "../data/emojis/types";

export type ProcessedBoost = {
  id: string;
  date: string;
  inputBoosts: number[];
  outputBoost: number;
  emojiReward?: Emoji;
};

export function processXPBoosts(boosts: XPBoost[]): ProcessedBoost[] {
  // Filter boosts that have been forged into another boost
  const forgedBoosts = boosts.filter(
    (boost) => boost.forgedIntoId !== undefined
  );

  // Group forged boosts by their forgedIntoId
  const groupedBoosts = forgedBoosts.reduce((acc, boost) => {
    if (boost.forgedIntoId) {
      if (!acc[boost.forgedIntoId]) {
        acc[boost.forgedIntoId] = [];
      }
      acc[boost.forgedIntoId].push(boost);
    }
    return acc;
  }, {} as Record<string, XPBoost[]>);

  // Process each group of forged boosts
  return Object.entries(groupedBoosts).map(([forgedIntoId, inputBoosts]) => {
    const outputBoost = boosts.find(
      (boost) => boost.id === forgedIntoId && boost.source === "XP_FORGE"
    );

    if (!outputBoost) {
      throw new Error(
        `Output boost not found for forgedIntoId: ${forgedIntoId}`
      );
    }

    return {
      id: outputBoost.id,
      date: outputBoost.createdAt,
      inputBoosts: inputBoosts.map((boost) => boost.value),
      outputBoost: outputBoost.value,
      emojiReward: outputBoost.emojiReward,
    };
  });
}
