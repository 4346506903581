import { client } from "../client";

type LoginUserRequestDTO = {
  email: string;
  password: string;
};
type LoginUserResponseDTO = {
  accessToken: string;
  userId: string;
  companyId: string;
  teamId: string;
  isCompanyAdmin: boolean;
};

export const loginUser = async (params: LoginUserRequestDTO) => {
  const res = await client.post<LoginUserResponseDTO>("/auth/local/login", {
    ...params,
  });

  return res.data;
};
