export const getDisplayName = (firstName: string, lastName?: string) => {
  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName.charAt(0)}.`;
};

export const getFallbackName = (
  firstName: string,
  lastName?: string
): string => {
  if (!lastName) {
    const [left, right] = firstName.split(" ");

    if (!right) {
      return left.charAt(0);
    }

    return getFallbackName(left, right);
  }

  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

/**
 * Generates a consistent Tailwind background color class from a string
 * @param {string} str - Input string to generate color from
 * @param {string[]} [colorClasses] - Optional array of Tailwind color classes to use
 * @returns {string} Tailwind background color class
 */
export const getColorFromString = (
  str: string,
  colorClasses = [
    // Red
    "bg-red-200",
    "bg-red-300",
    "bg-red-400",
    // Orange
    "bg-orange-200",
    "bg-orange-300",
    "bg-orange-400",
    // Amber
    "bg-amber-200",
    "bg-amber-300",
    "bg-amber-400",
    // Yellow
    "bg-yellow-200",
    "bg-yellow-300",
    "bg-yellow-400",
    // Lime
    "bg-lime-200",
    "bg-lime-300",
    "bg-lime-400",
    // Green
    "bg-green-200",
    "bg-green-300",
    "bg-green-400",
    // Emerald
    "bg-emerald-200",
    "bg-emerald-300",
    "bg-emerald-400",
    // Teal
    "bg-teal-200",
    "bg-teal-300",
    "bg-teal-400",
    // Cyan
    "bg-cyan-200",
    "bg-cyan-300",
    "bg-cyan-400",
    // Sky
    "bg-sky-200",
    "bg-sky-300",
    "bg-sky-400",
    // Blue
    "bg-blue-200",
    "bg-blue-300",
    "bg-blue-400",
    // Indigo
    "bg-indigo-200",
    "bg-indigo-300",
    "bg-indigo-400",
    // Violet
    "bg-violet-200",
    "bg-violet-300",
    "bg-violet-400",
    // Purple
    "bg-purple-200",
    "bg-purple-300",
    "bg-purple-400",
    // Fuchsia
    "bg-fuchsia-200",
    "bg-fuchsia-300",
    "bg-fuchsia-400",
    // Pink
    "bg-pink-200",
    "bg-pink-300",
    "bg-pink-400",
    // Rose
    "bg-rose-200",
    "bg-rose-300",
    "bg-rose-400",
  ]
) => {
  // Generate a hash value from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Make hash positive and get an index within our colorClasses array
  hash = Math.abs(hash);
  const index = hash % colorClasses.length;

  return colorClasses[index];
};

/**
 * Determines appropriate text color (black or white) for a given Tailwind background class
 * @param {string} bgClass - Tailwind background color class
 * @returns {string} Tailwind text color class ('text-black' or 'text-white')
 */
export const getTextColorForBackground = (bgClass: string) => {
  // All 200-400 shades can safely use black text
  // except a few darker colors that need white text for contrast
  const needsWhiteText = [
    // 400 shades that need white text
    "bg-slate-400",
    "bg-gray-400",
    "bg-zinc-400",
    "bg-neutral-400",
    "bg-stone-400",
    "bg-indigo-400",
    "bg-violet-400",
    "bg-purple-400",
  ];

  return needsWhiteText.includes(bgClass) ? "text-white" : "text-black";
};
