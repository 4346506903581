export const getIconBySportType = (type: string): string => {
  const emojis: Record<string, string> = {
    biking: "🚴‍♂️",
    running: "🏃",
    swimming: "🏊",
    walking: "🚶",
    badminton: "🏸",
    basketball: "🏀",
    boxing: "🥊",
    golfing: "🏌️",
    hockey: "🏑",
    ice_hockey: "🏒",
    horseback_riding: "🏇",
    martial_arts: "🥋",
    paddle: "🎾",
    soccer: "⚽",
    tennis: "🎾",
    yoga: "🧘",
    cardio: "🫀",
    weightlifting: "🏋️",
    rowing: "🚣",
    zumba: "🕺",
    dancing: "💃",
  };

  return emojis[type] || "🏅";
};

export type PaceUnit = "KM/H" | "MIN/KM" | "MIN/100M";

export type SportType =
  | "biking"
  | "walking"
  | "running"
  | "swimming"
  | "other"
  | "badminton"
  | "basketball"
  | "boxing"
  | "golfing"
  | "hockey"
  | "ice_hockey"
  | "horseback_riding"
  | "martial_arts"
  | "paddle"
  | "soccer"
  | "tennis"
  | "yoga"
  | "cardio"
  | "calisthenics"
  | "weightlifting"
  | "rowing"
  | "pilates"
  | "zumba"
  | "dancing";

type SportConfig = {
  name: string;
  id: SportType;
  requiresDistance: boolean;
  requiresElevation: boolean;
};

export const sportConfigs: SportConfig[] = [
  {
    name: "Walking",
    id: "walking",
    requiresDistance: true,
    requiresElevation: true,
  },
  {
    name: "Running",
    id: "running",
    requiresDistance: true,
    requiresElevation: true,
  },
  {
    name: "Biking",
    id: "biking",
    requiresDistance: true,
    requiresElevation: true,
  },
  {
    name: "Swimming",
    id: "swimming",
    requiresDistance: true,
    requiresElevation: false,
  },
  {
    name: "Badminton",
    id: "badminton",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Basketball",
    id: "basketball",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Boxing",
    id: "boxing",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Golf",
    id: "golfing",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Hockey",
    id: "hockey",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Ice Hockey",
    id: "ice_hockey",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Horseback riding",
    id: "horseback_riding",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Martial Arts",
    id: "martial_arts",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Paddle",
    id: "paddle",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Soccer",
    id: "soccer",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Tennis",
    id: "tennis",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Yoga",
    id: "yoga",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Cardio",
    id: "cardio",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Calisthenics",
    id: "calisthenics",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Weightlifting",
    id: "weightlifting",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Rowing",
    id: "rowing",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Pilates",
    id: "pilates",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Zumba",
    id: "zumba",
    requiresDistance: false,
    requiresElevation: false,
  },
  {
    name: "Dancing",
    id: "dancing",
    requiresDistance: false,
    requiresElevation: false,
  },
];

export const getPaceFormatBySportType = (type: string): PaceUnit => {
  if (type === "biking") {
    return "KM/H";
  }

  if (type === "running") {
    return "MIN/KM";
  }

  if (type === "swimming") {
    return "MIN/100M";
  }

  return "MIN/KM";
};
