import { client } from "../../client";
import { BingoCard } from "./types";

type GetBingoCardResponseDTO = BingoCard;

export const getBingoCard = async () => {
  const res = await client.get<GetBingoCardResponseDTO>(`bingo`);

  return res.data;
};
