import { BingoChallenge } from "../../../data/minigames/bingo/types";
import { formatTime } from "../../../lib/date-utils";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Button } from "../button";
import {
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../drawer";

interface BingoDrawerProps {
  challenge: BingoChallenge;
  currentWeek: number;
}

export const BingoDrawer = ({ challenge, currentWeek }: BingoDrawerProps) => {
  const teamId = useAuthStore((store) => store.teamId);

  const teamProgress = challenge.progress.filter((p) => p.teamId === teamId);
  const totalProgress = challenge.type.includes("TOTAL_")
    ? teamProgress.reduce((sum, contributor) => sum + contributor.progress, 0)
    : Math.max(
        0,
        ...challenge.progress
          .filter((p) => p.teamId === teamId)
          .map((p) => p.progress)
      );

  const isCompleted =
    currentWeek >= challenge.week && totalProgress >= challenge.objective;

  const progressText = challenge.type.includes("DISTANCE")
    ? totalProgress / 1000
    : challenge.type.includes("DURATION")
    ? formatTime(
        totalProgress,
        totalProgress >= 3600 ? "HH:mm" : totalProgress === 0 ? "m" : "mm"
      )
    : totalProgress;

  const objectiveText = challenge.type.includes("DISTANCE")
    ? challenge.objective / 1000
    : challenge.type.includes("DURATION")
    ? formatTime(
        challenge.objective,
        challenge.objective >= 3600 ? "HH:mm" : "mm"
      )
    : challenge.objective;

  const objectiveUnit = challenge.type.includes("DISTANCE")
    ? "km"
    : challenge.type.includes("DURATION")
    ? challenge.objective >= 3600
      ? "hours"
      : "minutes"
    : challenge.type.includes("CALORIES")
    ? "calories"
    : "times";

  return (
    <DrawerContent>
      <div className="mx-auto w-full max-w-sm p-4">
        <DrawerHeader>
          <DrawerTitle className="text-2xl font-bold flex items-center gap-2">
            {challenge.sport.charAt(0).toUpperCase() + challenge.sport.slice(1)}{" "}
            Challenge
          </DrawerTitle>

          <DrawerDescription className="text-left">
            Objective: {objectiveText} {objectiveUnit}{" "}
            {challenge.type.includes("TOTAL_") && "as a team"}
            {challenge.sport === "other" && (
              <>
                <br />
                <small>
                  * Any sport other than walking, running, biking or swimming.
                </small>
              </>
            )}
          </DrawerDescription>
        </DrawerHeader>

        <div className="flex flex-col gap-2">
          <div className="w-full bg-gray-200 rounded-md h-2 mb-1">
            <div
              className={`h-2 rounded-md ${
                isCompleted ? "bg-green-600" : "bg-blue-600"
              }`}
              style={{
                width: `${Math.min(
                  (totalProgress / challenge.objective) * 100,
                  100
                )}%`,
              }}
            />
          </div>

          <div className="text-center">
            {progressText} / {objectiveText} {objectiveUnit}
          </div>

          {teamProgress.length > 0 && (
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-medium text-muted-foreground">
                Contributors
              </h3>

              <div className="flex flex-wrap gap-2">
                {teamProgress
                  .sort((a, b) => b.progress - a.progress)
                  .map((p) => (
                    <div
                      key={p.user.id}
                      className="flex flex-col gap-2 items-center"
                    >
                      <Avatar affects="noborder" size="xs">
                        <AvatarImage src={p.user.avatarUrl} />
                        <AvatarFallback>
                          {getFallbackName(p.user.firstName, p.user.lastName)}
                        </AvatarFallback>
                      </Avatar>
                      <p>
                        {challenge.type.includes("DISTANCE")
                          ? p.progress / 1000
                          : challenge.type.includes("DURATION")
                          ? formatTime(p.progress, "HH:mm")
                          : p.progress}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <DrawerFooter>
          <DrawerClose asChild>
            <Button variant="outline">Close</Button>
          </DrawerClose>
        </DrawerFooter>
      </div>
    </DrawerContent>
  );
};
