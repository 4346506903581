import { PlusIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ProfileActivity } from "../../../data/activities/get-user-activities";
import { User } from "../../../data/auth/get-user";
import { Streak, UserStats } from "../../../data/auth/get-user-stats";
import { emojiRarityOrder } from "../../../data/emojis/types";
import { Friend } from "../../../data/profile/get-friends";
import { formatTime } from "../../../lib/date-utils";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { UserTotalDistances } from "../../cards/user-total-distance";
import { XPBoostStatsCard } from "../../cards/xp-boost-stats";
import { UserXPContribution } from "../../charts/user-exp-contribution";
import { ActivitiesTable } from "../../tables/activities";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Button } from "../button";
import { EmojiReaction } from "../home/emoji-reaction";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../tabs";

type ProfileContentProps = {
  userId: string;
  userStats?: UserStats;
  activities: ProfileActivity[];
  friends: Friend[];
  user?: User;
};

export const ProfileContent = (props: ProfileContentProps) => {
  const navigate = useNavigate();

  const loggedInUserId = useAuthStore((store) => store.userId);

  const formattedTotalActivities = (streak?: Streak) => {
    if (!streak) return 0;

    if (streak.consecutiveWeeks <= 0) return 0;

    const avg = (streak.totalActivities / streak.consecutiveWeeks).toFixed(2);

    if (avg.endsWith(".00")) return parseInt(avg);

    return avg;
  };

  const formattedTotalDuration = (streak?: Streak) => {
    if (!streak) return 0;

    if (streak.consecutiveWeeks <= 0) return "0h";

    return formatTime(
      streak.totalDuration / streak.consecutiveWeeks,
      "HH[h]mm"
    );
  };

  return (
    <div className="flex flex-col md:flex-row w-full rounded-md gap-4">
      <div className="w-full flex flex-col gap-4 md:w-2/3 order-2 md:order-1 pb-20 md:pb-4">
        <div className="flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4">
          <Typography variant="h3">Features</Typography>

          <div
            className={`grid gap-2 ${
              props.userId === loggedInUserId ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            <div
              className="p-2 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer"
              onClick={() => navigate(`/grid-reveal/${props.userId}`)}
            >
              <Typography variant="p" className="text-md font-bold">
                Grid Reveal
              </Typography>

              <Typography variant="p" className="text-xs">
                Gain XP Boosts by claiming cells
              </Typography>
            </div>

            {props.userId === loggedInUserId && (
              <div
                className={`p-2 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer`}
                onClick={() => navigate("/xp-forge")}
              >
                <Typography variant="p" className="text-md font-bold">
                  XP Forge
                </Typography>

                <Typography variant="p" className="text-xs">
                  Merge XP boosts together
                </Typography>
              </div>
            )}
          </div>

          <div
            className={`grid gap-2 ${
              props.userId === loggedInUserId ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            {props.userId === loggedInUserId && (
              <div
                className={`p-2 bg-gray-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer`}
                onClick={() => navigate("/training-plans")}
              >
                <Typography variant="p" className="text-md font-bold">
                  Training Plans
                </Typography>

                <Typography variant="p" className="text-xs">
                  Use Miles' recommendations to get to your objective 🚀
                </Typography>
              </div>
            )}

            <div className={`flex flex-row p-2 bg-gray-100 rounded-md`}>
              <div className="flex h-full justify-center items-center mr-2">
                <picture>
                  <source
                    srcSet={`https://fonts.gstatic.com/s/e/notoemoji/latest/1f525/512.webp`}
                    type="image/webp"
                    width="32"
                    height="32"
                  />
                  <img
                    src={`https://fonts.gstatic.com/s/e/notoemoji/latest/1f525/512.webp`}
                    alt={"Fire emoji representing user streak"}
                    width="32"
                    height="32"
                  />
                </picture>
              </div>

              <div>
                <Typography variant="p" className="text-md font-bold">
                  {props.userStats
                    ? props.userStats.activeStreak.consecutiveWeeks
                    : 0}{" "}
                  Week Streak
                </Typography>

                <Typography variant="p" className="text-xs">
                  Avg activities:{" "}
                  <b>
                    {formattedTotalActivities(props.userStats?.activeStreak)}
                  </b>{" "}
                  Avg duration:{" "}
                  <b>{formattedTotalDuration(props.userStats?.activeStreak)}</b>
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <ActivitiesTable activities={props.activities} showUserColumn={false} />
      </div>

      <div className="w-full md:w-1/3 order-1 md:order-2 flex flex-col gap-4">
        <div className="flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row items-end gap-3">
              <Typography
                variant="h3"
                className="hover:text-blue-400 hover:cursor-pointer"
                onClick={() =>
                  navigate(
                    `/friends${
                      loggedInUserId !== props.userId ? `/${props.userId}` : ""
                    }`
                  )
                }
              >
                Friends
              </Typography>

              <Typography
                variant="h3"
                affects="light"
                className="text-blue-500"
              >
                {props.friends.length}
              </Typography>
            </div>

            {props.userId === loggedInUserId && (
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() =>
                  navigate("/friends", {
                    state: {
                      defaultTab: "add",
                    },
                  })
                }
              >
                <PlusIcon className="h-4 w-4" />
              </Button>
            )}
          </div>

          <div className="grid grid-cols-8 grid-rows-1 gap-2">
            {props.friends.slice(0, 6).map((friend) => (
              <Avatar
                key={friend.id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`/profile/${friend.id}`, { replace: true })
                }
              >
                <AvatarImage src={friend?.avatarUrl} />
                <AvatarFallback>
                  {getFallbackName(friend.firstName, friend.lastName)}
                </AvatarFallback>
              </Avatar>
            ))}

            {props.friends.length === 7 && (
              <Avatar
                key={props.friends[6].id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`/profile/${props.friends[6].id}`, { replace: true })
                }
              >
                <AvatarImage src={props.friends[6]?.avatarUrl} />
                <AvatarFallback>
                  {getFallbackName(
                    props.friends[6].firstName,
                    props.friends[6].lastName
                  )}
                </AvatarFallback>
              </Avatar>
            )}

            {props.friends.length > 7 && (
              <div className="relative inline-block">
                {/* special on click to friends page */}
                <Avatar>
                  <AvatarImage src={props.friends[6]?.avatarUrl} />
                  <AvatarFallback>
                    {getFallbackName(
                      props.friends[6].firstName,
                      props.friends[6].lastName
                    )}
                  </AvatarFallback>
                </Avatar>

                <div className="absolute inset-0 aspect-square h-12 w-12 flex items-center justify-center bg-black bg-opacity-40 rounded">
                  <p className="text-white text-lg font-bold">
                    +{props.friends.length - 6}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Tabs defaultValue="distance" className="w-full">
          <TabsList className="flex items-center justify-start flex-wrap h-auto space-y-1">
            <TabsTrigger className="flex-1" value="distance">
              Distance
            </TabsTrigger>

            <TabsTrigger className="flex-1" value="experience">
              Experience
            </TabsTrigger>

            <TabsTrigger className="flex-1" value="xpboosts">
              XP Boosts
            </TabsTrigger>

            <TabsTrigger className="flex-1" value="emojis">
              Emojis
            </TabsTrigger>
          </TabsList>

          <TabsContent value="distance">
            <UserTotalDistances
              bikingDistance={props.userStats?.biking?.totalDistance || 0}
              runningDistance={props.userStats?.running?.totalDistance || 0}
              swimmingDistance={props.userStats?.swimming?.totalDistance || 0}
              walkingDistance={props.userStats?.walking?.totalDistance || 0}
            />
          </TabsContent>

          <TabsContent value="experience">
            <UserXPContribution
              companyContribution={props.userStats?.companyContribution || 0}
              companyLevel={props.user?.companyLevel || 1}
            />
          </TabsContent>

          <TabsContent value="xpboosts">
            <XPBoostStatsCard
              stats={
                props.userStats?.xpBoosts || {
                  nbApplied: 0,
                  nbAvailable: 0,
                  nbForged: 0,
                  totalPercentAvailable: 0,
                  totalPercentUsed: 0,
                  totalXPGiven: 0,
                }
              }
            />
          </TabsContent>

          <TabsContent value="emojis">
            <div className="h-fit w-full bg-white rounded-md border border-slate-200 p-4 flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full h-full">
                <Typography variant="h3">Emoji Reactions</Typography>

                <div className="flex flex-row gap-1">
                  <Typography variant="p" affects="muted" className="text-sm">
                    Received
                  </Typography>

                  <p className="text-blue-400 font-bold text-sm">
                    {Object.values(
                      props.userStats?.emojisReceived || {}
                    ).reduce((result, curr) => (result += curr.amount), 0)}
                  </p>

                  <Typography variant="p" affects="muted" className="text-sm">
                    emojis, for a total of
                  </Typography>

                  <p className="text-blue-400 font-bold text-sm">
                    {Object.values(
                      props.userStats?.emojisReceived || {}
                    ).reduce(
                      (result, curr) => (result += curr.amount * curr.xpReward),
                      0
                    )}{" "}
                    XP
                  </p>
                </div>

                <div
                  className="grid grid-flow-row gap-2"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill, minmax(64px, 1fr))",
                  }}
                >
                  {Object.entries(props.userStats?.emojisReceived || {})
                    .map(([emojiId, userEmoji]) => ({
                      emojiId,
                      ...userEmoji,
                    }))
                    .sort((a, b) => {
                      const rarityDiff =
                        emojiRarityOrder.indexOf(a.rarity) -
                        emojiRarityOrder.indexOf(b.rarity);
                      return rarityDiff === 0
                        ? b.amount - a.amount
                        : rarityDiff;
                    })
                    .map((userEmoji) => (
                      <EmojiReaction
                        key={userEmoji.id}
                        {...userEmoji}
                        isHighlighted={false}
                        users={userEmoji.users}
                      />
                    ))}
                </div>
              </div>

              <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row gap-1">
                  <Typography variant="p" affects="muted" className="text-sm">
                    Gave
                  </Typography>

                  <p className="text-blue-400 font-bold text-sm">
                    {Object.values(props.userStats?.emojisGiven || {}).reduce(
                      (result, curr) => (result += curr.amount),
                      0
                    )}
                  </p>

                  <Typography variant="p" affects="muted" className="text-sm">
                    emojis, for a total of
                  </Typography>

                  <p className="text-blue-400 font-bold text-sm">
                    {Object.values(props.userStats?.emojisGiven || {}).reduce(
                      (result, curr) => (result += curr.amount * curr.xpReward),
                      0
                    )}{" "}
                    XP
                  </p>
                </div>

                <div
                  className="grid grid-flow-row gap-2"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill, minmax(64px, 1fr))",
                  }}
                >
                  {Object.entries(props.userStats?.emojisGiven || {})
                    .map(([emojiId, userEmoji]) => ({
                      emojiId,
                      ...userEmoji,
                    }))
                    .sort((a, b) => {
                      const rarityDiff =
                        emojiRarityOrder.indexOf(a.rarity) -
                        emojiRarityOrder.indexOf(b.rarity);
                      return rarityDiff === 0
                        ? b.amount - a.amount
                        : rarityDiff;
                    })
                    .map((userEmoji) => (
                      <EmojiReaction
                        key={userEmoji.id}
                        {...userEmoji}
                        isHighlighted={false}
                        users={userEmoji.users}
                      />
                    ))}
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
