import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLeaderboardsAll,
  LeaderboardUser,
} from "../../data/home/get-leaderboard-all";
import { getDisplayName, getFallbackName } from "../../lib/name-utils";
import { useAuthStore } from "../../stores/auth.store";
import Typography from "../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export type SortOption =
  | "nbActivities"
  | "streak"
  | "gridLevel"
  | "xpBoostsForged"
  | "level"
  | "emojisGiven";

const sortOptions: { label: string; value: SortOption }[] = [
  { label: "Most Activities", value: "nbActivities" },
  { label: "Most XP Boosts Forged", value: "xpBoostsForged" },
  { label: "Most Emojis Given", value: "emojisGiven" },
  { label: "Most Grids Completed", value: "gridLevel" },
  { label: "Highest Active Streaks", value: "streak" },
  { label: "Highest Level", value: "level" },
];

const randomSortOption = (): SortOption => {
  const options: SortOption[] = [
    "nbActivities",
    "streak",
    "gridLevel",
    "xpBoostsForged",
    "level",
    "emojisGiven",
  ];

  return options[Math.floor(Math.random() * options.length)];
};

export default function Leaderboard() {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);

  const [users, setUsers] = useState<LeaderboardUser[]>([]);
  const [sortOption, setSortOption] = useState<SortOption>(randomSortOption());

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => b[sortOption] - a[sortOption]);
  }, [users, sortOption]);

  const visibleColumns = useMemo(() => ["name", sortOption], [sortOption]);

  useEffect(() => {
    const getLeaderboardAll = async () => {
      const data = await getLeaderboardsAll();

      setUsers(data);
    };

    getLeaderboardAll();
  }, []);

  return (
    <div className="flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4 mt-4">
      <h1 className="text-2xl font-semibold">Leaderboards</h1>

      <Select
        onValueChange={(value) => setSortOption(value as SortOption)}
        defaultValue={sortOption}
      >
        <SelectTrigger className="w-80">
          <SelectValue placeholder="Sort by" />
        </SelectTrigger>

        <SelectContent>
          {sortOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="w-full h-full relative overflow-auto">
        <Table>
          <TableHeader className="sticky top-0 bg-white z-50">
            <TableRow>
              <TableHead className="w-[20%]">Rank</TableHead>
              <TableHead className="w-[60%]">User</TableHead>
              {visibleColumns.includes("streak") && (
                <TableHead>Streak</TableHead>
              )}
              {visibleColumns.includes("gridsClaimed") && (
                <TableHead>Grids</TableHead>
              )}
              {visibleColumns.includes("xpBoostsForged") && (
                <TableHead>Boosts</TableHead>
              )}
              {visibleColumns.includes("level") && <TableHead>Level</TableHead>}
              {visibleColumns.includes("emojisGiven") && (
                <TableHead>Given</TableHead>
              )}
              {visibleColumns.includes("nbActivities") && (
                <TableHead>Activities</TableHead>
              )}
            </TableRow>
          </TableHeader>

          <TableBody>
            {sortedUsers.map((user, index) => (
              <TableRow
                key={user.id}
                className={`${user.id === userId && "bg-blue-50"}`}
              >
                <TableCell>
                  {index < 3 ? (
                    <div
                      className={`w-8 h-8 rounded-md flex items-center justify-center text-white font-bold ${
                        index === 0
                          ? "bg-yellow-400"
                          : index === 1
                          ? "bg-gray-400"
                          : "bg-orange-400"
                      }`}
                    >
                      {index + 1}
                    </div>
                  ) : (
                    <div className="w-8 h-8 rounded-md flex items-center justify-center text-black font-bold bg-gray-100">
                      {index + 1}
                    </div>
                  )}
                </TableCell>

                <TableCell>
                  <div className="flex items-center space-x-3">
                    <Avatar
                      affects="noborder"
                      size="xs"
                      className="hover:cursor-pointer"
                      onClick={() => navigate(`/profile/${user.id}`)}
                    >
                      <AvatarImage src={user.avatarUrl} />
                      <AvatarFallback>
                        {getFallbackName(user.firstName, user.lastName)}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <Typography
                        variant="p"
                        affects="link"
                        className="text-sm truncate max-w-32"
                        onClick={() => navigate(`/profile/${user.id}`)}
                      >
                        {getDisplayName(user.firstName, user.lastName)}
                      </Typography>
                      <div className="text-sm text-gray-500">
                        {user.team?.codeName || "No team"}
                      </div>
                    </div>
                  </div>
                </TableCell>

                {visibleColumns.includes("streak") && (
                  <TableCell>{user.streak}</TableCell>
                )}
                {visibleColumns.includes("gridLevel") && (
                  <TableCell>{Math.max(0, user.gridLevel - 1)}</TableCell>
                )}
                {visibleColumns.includes("xpBoostsForged") && (
                  <TableCell>{user.xpBoostsForged}</TableCell>
                )}
                {visibleColumns.includes("level") && (
                  <TableCell>{user.level}</TableCell>
                )}
                {visibleColumns.includes("emojisGiven") && (
                  <TableCell>{user.emojisGiven}</TableCell>
                )}
                {visibleColumns.includes("nbActivities") && (
                  <TableCell>{user.nbActivities}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
