import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { loginUser } from "../data/auth/user-login";
import { registerUser } from "../data/auth/user-register";

type AuthStore = {
  accessToken: string;
  userId: string;
  companyId: string;
  teamId: string;
  isCompanyAdmin: boolean;
  isVerified: boolean;

  setCompanyId: (companyId: string, isCompanyAdmin: boolean) => void;
  setTeamId: (teamId: string) => void;
  setIsVerified: (value: boolean) => void;

  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      accessToken: "",
      userId: "",
      companyId: "",
      teamId: "",
      isCompanyAdmin: false,

      // isVerified is set to true by default in order to not display the "verify to claim xp boost" banner.
      // Navigating loading your user updates this value.
      isVerified: true,

      setCompanyId: (companyId, isCompanyAdmin) => {
        set({ companyId, isCompanyAdmin });
      },
      setTeamId: (teamId) => {
        set({ teamId });
      },
      setIsVerified: (value) => {
        set({ isVerified: value });
      },

      register: async (
        email: string,
        password: string,
        firstName: string,
        lastName: string
      ) => {
        const { accessToken, userId } = await registerUser({
          email,
          password,
          firstName,
          lastName,
        });

        localStorage.setItem("accessToken", accessToken);
        set({
          accessToken,
          userId,
          companyId: "",
          teamId: "",
          isVerified: false,
        });

        window.location.replace("/home");
      },

      login: async (email: string, password: string) => {
        const { accessToken, userId, companyId, teamId, isCompanyAdmin } =
          await loginUser({
            email,
            password,
          });

        localStorage.setItem("accessToken", accessToken);
        set({ accessToken, userId, companyId, teamId, isCompanyAdmin });

        window.location.replace("/home");
      },

      logout: () => {
        localStorage.setItem("accessToken", "");
        set({ accessToken: "", userId: "", companyId: "", teamId: "" });

        window.location.replace("/login");
      },
    }),
    {
      name: "auth-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
