import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { Activity as ActivityType } from "../../../data/activities/get-home";
import { formatDate, formatPace, formatTime } from "../../../lib/date-utils";
import dayjs from "../../../lib/dayjs";
import { getDisplayName, getFallbackName } from "../../../lib/name-utils";
import {
  getIconBySportType,
  getPaceFormatBySportType,
  sportConfigs,
} from "../../../lib/sport-type-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { ApplyXPBoost } from "../../dialogs/apply-xp-boost-dialog";
import Typography from "../../typography";
import ActivityEmojiReactions from "./activity-emoji-reactions";
import ActivityStat from "./activity-stat";

const Activity = ({ activity }: { activity: ActivityType }) => {
  const navigate = useNavigate();
  const userId = useAuthStore((store) => store.userId);

  return (
    <Card key={activity.id} className="overflow-hidden rounded-md">
      <CardContent className="flex flex-col gap-2 p-2 w-full">
        <div className="flex items-center">
          <div className="flex w-full items-center">
            <Avatar
              className="h-10 w-10 hover:cursor-pointer"
              onClick={() => navigate(`/profile/${activity.author.id}`)}
            >
              <AvatarImage
                src={activity.author.avatarUrl}
                alt={`${activity.author.firstName}'s avatar`}
              />
              <AvatarFallback>
                {getFallbackName(
                  activity.author.firstName,
                  activity.author.lastName
                )}
              </AvatarFallback>
            </Avatar>

            <div className="ml-3">
              <Typography
                variant="h3"
                affects="link"
                className="text-sm font-semibold"
                onClick={() => navigate(`/profile/${activity.author.id}`)}
              >
                {getDisplayName(
                  activity.author.firstName,
                  activity.author.lastName
                )}
              </Typography>
              <p className="text-xs text-muted-foreground">
                {activity.author.team
                  ? `${activity.author.team.codeName} • `
                  : ""}
                Level {activity.author.level} •{" "}
                {formatDate(activity.createdAt, "DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-7 gap-3 text-sm">
          <ActivityStat
            label="Sport"
            icon={getIconBySportType(activity.type)}
            value={
              activity.type.charAt(0).toUpperCase() + activity.type.slice(1)
            }
          />

          {sportConfigs.find((s) => s.id === activity.type)
            ?.requiresDistance && (
            <ActivityStat
              label="Distance"
              icon="📏"
              value={activity.distance.toFixed(1)}
              unit="KM"
            />
          )}

          <ActivityStat
            label="Duration"
            icon="⏱️"
            value={formatTime(activity.duration, "HH:mm:ss")}
          />

          {sportConfigs.find((s) => s.id === activity.type)
            ?.requiresDistance && (
            <ActivityStat
              label="Pace"
              icon="💨"
              value={formatPace(
                activity.duration,
                activity.distance,
                activity.type
              ).toString()}
              unit={getPaceFormatBySportType(activity.type)}
            />
          )}

          {sportConfigs.find((s) => s.id === activity.type)
            ?.requiresElevation && (
            <ActivityStat
              label="Elevation"
              icon="🏔️"
              value={activity.elevation.toFixed()}
              unit="M"
            />
          )}

          <ActivityStat
            label="Calories"
            icon="🔥"
            value={activity.experience.toFixed()}
            unit="Cal"
          />

          <div className="flex flex-col">
            <p className="text-xs font-medium text-muted-foreground">
              Bonus XP 💫
            </p>

            <div className="flex items-baseline">
              {activity.author.id === userId &&
              !activity.xpBoostId &&
              dayjs(activity.createdAt).add(1, "day").toDate() >= new Date() ? (
                <ApplyXPBoost
                  activityId={activity.id}
                  activityExp={activity.experience}
                  activityCreatedAt={activity.createdAt}
                />
              ) : (
                <div className="flex items-baseline">
                  <p
                    className={`text-sm font-bold ${
                      activity.bonusExp > 0 && "animate-rainbow"
                    }`}
                  >
                    {activity.bonusExp}
                  </p>
                  <p className="text-xs font-light ml-1">
                    EXP{" "}
                    {activity.bonusExp > 0 &&
                      `(${Math.round(
                        (activity.bonusExp / activity.experience) * 100
                      )}%)`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <ActivityEmojiReactions activity={activity} />
      </CardContent>
    </Card>
  );
};

export default Activity;
