import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { MonthlyActivityStats } from "../../data/company/get-company-activity-stats";
import Typography from "../typography";
import { SportsLegend } from "../ui/sports-legend";

const chartConfig = {
  rides: {
    label: "Biking",
    color: "hsl(var(--chart-1))",
  },
  runs: {
    label: "Running",
    color: "hsl(var(--chart-2))",
  },
  walks: {
    label: "Walking",
    color: "hsl(var(--chart-3))",
  },
  swims: {
    label: "Swimming",
    color: "hsl(var(--chart-4))",
  },
} satisfies ChartConfig;

type TotalActivitiesBarChartProps = {
  chartData: MonthlyActivityStats[];
};

export const TotalActivitiesBarChart = (
  props: TotalActivitiesBarChartProps
) => {
  return (
    <div className="w-full bg-white rounded-md border border-slate-200 p-4 mt-4 max-w-96">
      <p className="text-2xl font-semibold">Activities</p>
      <Typography variant="p" affects="muted" className="text-sm">
        All activities uploaded by company members
      </Typography>

      <ChartContainer config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={props.chartData}
          margin={{
            left: -30,
            top: 20,
          }}
        >
          <CartesianGrid vertical={false} />

          <XAxis
            dataKey="monthName"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <YAxis tickLine={false} allowDecimals={false} />

          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent indicator="dot" />}
          />

          <Bar
            dataKey="nbRides"
            name="Rides"
            fill="var(--color-rides)"
            radius={4}
          />
          <Bar
            dataKey="nbRuns"
            name="Runs"
            fill="var(--color-runs)"
            radius={4}
          />
          <Bar
            dataKey="nbWalks"
            name="Walks"
            fill="var(--color-walks)"
            radius={4}
          />
          <Bar
            dataKey="nbSwims"
            name="Swims"
            fill="var(--color-swims)"
            radius={4}
          />
        </BarChart>
      </ChartContainer>

      <SportsLegend />
    </div>
  );
};
