import { AvatarIcon, GearIcon, HomeIcon } from "@radix-ui/react-icons";
import {
  Building,
  CalendarClock,
  Grid3X3Icon,
  MessageSquareQuote,
} from "lucide-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UploadActivityDialog } from "../components/dialogs/upload-activity-dialog";
import { Button } from "../components/ui/button";

export const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const NavItem = ({
    href,
    icon: Icon,
    svgIcon,
    label,
  }: {
    href: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    svgIcon?: any;
  }) => (
    <Button
      variant="ghost"
      size="icon"
      className={`rounded-md ${pathname === href ? "bg-muted" : ""}`}
      onClick={() => navigate(href)}
      aria-label={label}
    >
      {Icon && <Icon className="size-5" />}
      {svgIcon}
    </Button>
  );

  const DesktopNavBar = () => (
    <nav className="flex flex-col p-2 h-full justify-between">
      <div className="flex flex-col gap-2 h-full">
        <NavItem href="/home" icon={HomeIcon} label="Home" />
        <NavItem href="/profile" icon={AvatarIcon} label="Profile" />
        <NavItem href="/company" icon={Building} label="Company" />
        <NavItem
          href="/training-plans"
          icon={CalendarClock}
          label="Training Plans"
        />
        <UploadActivityDialog />
        <NavItem href="/grid-reveal" icon={Grid3X3Icon} label="Grid Reveal" />
        <NavItem
          href="/xp-forge"
          svgIcon={
            <svg
              viewBox="-51.2 -51.2 614.40 614.40"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              stroke="#000000"
              stroke-width="0.00512"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke="#CCCCCC"
                stroke-width="2.048"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill="#000000"
                  d="M413.375 69.906L336.937 191.47l-8.25-32.69-30.218 88.97 62.655-29.375.22 29.438 127.03-50.938-70.813-1.97 47.782-68.686-73.47 39.25 21.5-95.564zM210.22 102.094l-32 14.406 16.874 55.656-177.813 80.03 12.564 27.876L207.656 200l30.406 49.47 49.313-22.19-21.344-70.343-55.81-54.843zM197.593 266.78v20.345h-88.906c15.994 38.807 51.225 65.43 88.906 74.28v32.97h58.562c-12.118 30.528-33.505 55.684-58.47 77.594H172.22v18.686H456.56V471.97h-27.406c-28.734-21.895-50.055-47.018-61.625-77.595h63.658v-29.188c19.748-6.995 39.5-19.51 59.25-36.687-19.812-17.523-39.23-27.25-59.25-31.938v-29.78H197.594z"
                ></path>
              </g>
            </svg>
          }
          label="XP Forge"
        />
        <NavItem
          href="/bingo"
          svgIcon={
            <svg
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="a"></g> <g id="b"></g> <g id="c"></g> <g id="d"></g>{" "}
                <g id="e"></g>{" "}
                <g id="f">
                  {" "}
                  <path
                    d="M12.07,5c-1.13,0-2.04,.91-2.04,2.04v14.72l20.63,3.94,23.31-3.94V7.04c0-1.13-.91-2.04-2.04-2.04H12.07Z"
                    fill="#8996a8"
                    fill-rule="evenodd"
                  ></path>{" "}
                  <path
                    d="M53.97,21.75H10.03V56.96c0,1.13,.91,2.04,2.04,2.04H51.94c1.13,0,2.04-.91,2.04-2.04V21.75Z"
                    fill="#f0f3f5"
                    fill-rule="evenodd"
                  ></path>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="40.09"
                    y="47.02"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="28.07"
                    y="47.02"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="16.05"
                    y="47.02"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="40.09"
                    y="36.91"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="28.07"
                    y="36.91"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="16.05"
                    y="36.91"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="40.09"
                    y="26.79"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="28.07"
                    y="26.79"
                  ></rect>{" "}
                  <rect
                    fill="#9fa9b6"
                    height="5.96"
                    width="7.86"
                    x="16.05"
                    y="26.79"
                  ></rect>{" "}
                  <path
                    d="M47.76,5c1.13,0,2.04,.91,2.04,2.04v15.42l4.17-.71V7.04c0-1.13-.91-2.04-2.04-2.04h-4.17Z"
                    fill="#8996a8"
                    fill-rule="evenodd"
                  ></path>{" "}
                  <path
                    d="M49.8,21.75V56.96c0,1.13-.91,2.04-2.04,2.04h4.17c1.13,0,2.04-.91,2.04-2.04V21.75h-4.17Z"
                    fill="#e4ecf0"
                    fill-rule="evenodd"
                  ></path>{" "}
                  <path
                    d="M45.72,52.97h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Zm24.03-10.11h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Zm24.03-10.11h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Zm-12.02,0h2.22v-5.96h-2.22v5.96Z"
                    fill="#8996a8"
                    fill-rule="evenodd"
                  ></path>{" "}
                  <path d="M9.03,7.04V56.96c0,1.67,1.36,3.04,3.04,3.04H51.93c1.67,0,3.04-1.36,3.04-3.04V7.04c0-1.67-1.36-3.04-3.04-3.04H12.07c-1.67,0-3.04,1.36-3.04,3.04ZM51.93,58H12.07c-.57,0-1.04-.46-1.04-1.04V22.75H52.97V56.96c0,.57-.46,1.04-1.04,1.04ZM12.07,6H51.93c.57,0,1.04,.46,1.04,1.04v13.72H11.03V7.04c0-.57,.47-1.04,1.04-1.04Z"></path>{" "}
                  <path d="M47.95,46.02h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M35.93,46.02h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M23.91,46.02h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M47.95,35.91h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M35.93,35.91h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M23.91,35.91h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M47.95,25.79h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M35.93,25.79h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M23.91,25.79h-7.86c-.55,0-1,.45-1,1v5.96c0,.55,.45,1,1,1h7.86c.55,0,1-.45,1-1v-5.96c0-.55-.45-1-1-1Zm-1,5.96h-5.86v-3.96h5.86v3.96Z"></path>{" "}
                  <path d="M18.09,16h2.96c1.43,0,2.6-1.17,2.6-2.6,0-.92-.47-1.72-1.19-2.19,.13-.31,.21-.66,.21-1.02,0-1.44-1.17-2.6-2.6-2.6h-1.98c-.55,0-1,.45-1,1v6.41c0,.55,.45,1,1,1Zm1-6.41h.98c.34,0,.6,.26,.6,.6s-.26,.6-.6,.6h-.98v-1.21Zm0,3.21h1.96c.34,0,.6,.26,.6,.6s-.26,.6-.6,.6h-1.96v-1.21Z"></path>{" "}
                  <path d="M25.59,15.45c.55,0,1-.45,1-1v-5.32c0-.55-.45-1-1-1s-1,.45-1,1v5.32c0,.55,.45,1,1,1Z"></path>{" "}
                  <path d="M28.54,15.45c.55,0,1-.45,1-1v-2.47l2.47,3.09c.19,.24,.48,.38,.78,.38,.11,0,.22-.02,.33-.06,.4-.14,.67-.52,.67-.94v-5.32c0-.55-.45-1-1-1s-1,.45-1,1v2.47l-2.47-3.09c-.27-.33-.71-.46-1.11-.32-.4,.14-.67,.52-.67,.94v5.32c0,.55,.45,1,1,1Z"></path>{" "}
                  <path d="M37.6,15.73c.77,0,1.48-.3,2.02-.83,.54-.54,.84-1.26,.84-2.02v-.94c0-.55-.45-1-1-1h-.92c-.55,0-1,.45-1,1,0,.52,.4,.95,.92,1-.01,.21-.1,.4-.25,.55-.16,.16-.38,.25-.61,.25-.48,0-.86-.38-.86-.86v-2.15c0-.47,.38-.86,.86-.86,.23,0,.45,.09,.61,.25,.39,.39,1.02,.39,1.41,0s.39-1.02,0-1.41c-.54-.54-1.26-.84-2.02-.84-1.57,0-2.86,1.28-2.86,2.86v2.15c0,1.57,1.28,2.86,2.86,2.86Z"></path>{" "}
                  <path d="M44.16,15.87c1.52,0,2.75-1.24,2.75-2.76v-2.63c0-1.52-1.24-2.75-2.75-2.75s-2.76,1.24-2.76,2.75v2.63c0,1.52,1.24,2.76,2.76,2.76Zm-.76-5.39c0-.42,.33-.75,.76-.75s.75,.34,.75,.75v2.63c0,.42-.34,.76-.75,.76s-.76-.33-.76-.76v-2.63Z"></path>{" "}
                  <path d="M25.17,19.24h13.66c.55,0,1-.45,1-1s-.45-1-1-1h-13.66c-.55,0-1,.45-1,1s.45,1,1,1Z"></path>{" "}
                </g>{" "}
                <g id="g"></g> <g id="h"></g> <g id="i"></g> <g id="j"></g>{" "}
                <g id="k"></g> <g id="l"></g> <g id="m"></g> <g id="n"></g>{" "}
                <g id="o"></g> <g id="p"></g> <g id="q"></g> <g id="r"></g>{" "}
                <g id="s"></g> <g id="t"></g> <g id="u"></g> <g id="v"></g>{" "}
                <g id="w"></g> <g id="x"></g> <g id="y"></g> <g id="a`"></g>{" "}
                <g id="aa"></g> <g id="ab"></g> <g id="ac"></g> <g id="ad"></g>{" "}
                <g id="ae"></g> <g id="af"></g> <g id="ag"></g> <g id="ah"></g>{" "}
                <g id="ai"></g> <g id="aj"></g> <g id="ak"></g> <g id="al"></g>{" "}
                <g id="am"></g> <g id="an"></g> <g id="ao"></g> <g id="ap"></g>{" "}
                <g id="aq"></g> <g id="ar"></g> <g id="as"></g> <g id="at"></g>{" "}
                <g id="au"></g> <g id="av"></g> <g id="aw"></g> <g id="ax"></g>{" "}
              </g>
            </svg>
          }
          label="Bingo"
        />
      </div>

      <div>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-md"
          aria-label="Feedback"
          asChild
        >
          <a
            href="https://marcoverbeek.notion.site/12a025664adf80c68993d900dc16ddf8"
            target="_blank"
          >
            <MessageSquareQuote className="size-5" />
          </a>
        </Button>

        <NavItem href="/settings" icon={GearIcon} label="Settings" />
      </div>
    </nav>
  );
  const MobileNavBar = () => (
    <nav className="fixed bottom-0 left-0 right-0 z-10 bg-background border-t md:hidden">
      <div className="flex flex-row justify-around items-center h-16 px-4">
        <NavItem href="/home" icon={HomeIcon} label="Home" />
        <NavItem href="/profile" icon={AvatarIcon} label="Profile" />
        <NavItem href="/company" icon={Building} label="Company" />
        <NavItem
          href="/training-plans"
          icon={CalendarClock}
          label="Training Plans"
        />
        <UploadActivityDialog />

        <div>
          <NavItem href="/settings" icon={GearIcon} label="Settings" />
        </div>
      </div>
    </nav>
  );

  return (
    <div className="flex h-full w-full flex-col bg-muted/40">
      {/* Desktop sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background md:flex ${
          process.env.NODE_ENV !== "production" ? "bg-blue-100" : ""
        }`}
      >
        <DesktopNavBar />
      </aside>

      {/* Main content */}
      <div className="flex flex-col md:pl-14 h-full">
        <div className="p-4 pb-20 md:pb-4 h-full">
          <Outlet />
        </div>
      </div>

      {/* Mobile bottom navbar */}
      <MobileNavBar />
    </div>
  );
};
