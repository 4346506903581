import { User } from "../auth/get-user";
import { client } from "../client";

type UpdateUserRequestDTO = {
  userId: string;
  firstName?: string;
  lastName?: string;
  teamId?: string;
  backgroundId?: string;
  avatarFile?: File;
  isEmailSubscribed?: boolean;
  weight?: number;
};
type UpdateUserResponseDTO = User;

export const updateUser = async (params: UpdateUserRequestDTO) => {
  const res = await client.patch<UpdateUserResponseDTO>(
    `/users/${params.userId}`,
    {
      firstName: params.firstName,
      lastName: params.lastName,
      teamId: params.teamId,
      backgroundId: params.backgroundId,
      isEmailSubscribed: params.isEmailSubscribed,
      weight: params.weight,
    }
  );

  const data = res.data;

  if (params.avatarFile) {
    const formData = new FormData();
    formData.append("avatar", params.avatarFile);

    await client.patch(`/users/${data.id}/avatars`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  return data;
};
