import { useMediaQuery } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import Typography from "../components/typography";
import { BingoCard } from "../components/ui/bingo/BingoCard";
import { BingoDrawer } from "../components/ui/bingo/BingoDrawer";
import { BingoLeaderboard } from "../components/ui/bingo/BingoLeaderboard";
import { BingoPrizes } from "../components/ui/bingo/BingoPrizes";
import { Drawer } from "../components/ui/drawer";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { getBingoCard } from "../data/minigames/bingo/get-bingo-cards";
import {
  BingoCard as BingoCardType,
  BingoChallenge,
} from "../data/minigames/bingo/types";
import { detailedFromNow } from "../lib/date-utils";
import dayjs from "../lib/dayjs";
import { useAuthStore } from "../stores/auth.store";

export const BingoOverview = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const userCompanyId = useAuthStore((store) => store.companyId);
  const userTeamId = useAuthStore((store) => store.teamId);

  const [bingoCard, setBingoCard] = useState<BingoCardType | null>(null);

  useEffect(() => {
    const getCard = async () => {
      if (!userCompanyId || !userTeamId) {
        return;
      }

      const bingoCard = await getBingoCard();
      setBingoCard(bingoCard);
    };

    getCard();
  }, []);

  const [selectedChallenge, setSelectedChallenge] =
    useState<BingoChallenge | null>(null);

  if (!userCompanyId) {
    return <p>You must be in a company to play Bingo.</p>;
  }

  if (!userTeamId) {
    return (
      <p>
        You must be in a team to play Bingo. Join one from the company page!
      </p>
    );
  }

  if (!bingoCard) {
    return <p>Loading</p>;
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <Drawer
        open={!!selectedChallenge}
        onClose={() => setSelectedChallenge(null)}
      >
        {selectedChallenge && (
          <BingoDrawer
            challenge={selectedChallenge}
            currentWeek={bingoCard.currentWeek}
          />
        )}
      </Drawer>

      <div
        className={`flex flex-col gap-1 ${isSmallDevice && "items-center"} `}
      >
        <Typography variant="h1">Bingo</Typography>

        {bingoCard.currentWeek < 3 ? (
          <p>
            <strong>Week {bingoCard.currentWeek + 1}</strong> starts{" "}
            {detailedFromNow(
              dayjs(bingoCard.startDate)
                .add(bingoCard.currentWeek + 1, "week")
                .startOf("isoWeek")
                .toDate()
            )}
            .
          </p>
        ) : bingoCard.currentWeek === 3 ? (
          <p>
            Card <strong>locks</strong>{" "}
            {detailedFromNow(dayjs(bingoCard.cooldownDate).toDate())}!
            <br />
            No progress will be awarded during that week.
          </p>
        ) : (
          <p>
            Congratulations on your progress!
            <br />
            <strong>Next card</strong> starts{" "}
            {dayjs(bingoCard.startDate)
              .add(5, "week")
              .startOf("isoWeek")
              .fromNow()}
            .
          </p>
        )}
      </div>

      <div className="flex flex-row w-full gap-4">
        <Tabs
          defaultValue="bingocard"
          className={`${isSmallDevice ? "w-full" : "hidden"}`}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="bingocard">Bingo Card</TabsTrigger>
            <TabsTrigger value="leaderboards">Leaderboards</TabsTrigger>
          </TabsList>

          <TabsContent value="bingocard" className="w-full justify-center">
            <BingoCard
              bingoCard={bingoCard}
              onChallengeClick={(challenge) => setSelectedChallenge(challenge)}
            />
          </TabsContent>

          <TabsContent value="leaderboards">
            <div className="flex flex-col gap-2">
              <BingoLeaderboard bingoCard={bingoCard} />
              <BingoPrizes />
            </div>
          </TabsContent>
        </Tabs>

        {!isSmallDevice && (
          <div className="w-full">
            <BingoCard
              bingoCard={bingoCard}
              onChallengeClick={(challenge) => setSelectedChallenge(challenge)}
            />
          </div>
        )}

        {!isSmallDevice && (
          <div className="flex flex-col gap-2 h-full min-w-80 max-w-96">
            <BingoLeaderboard bingoCard={bingoCard} />
            <BingoPrizes />
          </div>
        )}
      </div>
    </div>
  );
};
