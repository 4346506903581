import { ProcessedBoost } from "../../../lib/xp-forge-utils";
import Typography from "../../typography";
import { ScrollArea } from "../scroll-area";
import { ForgeHistoryItem } from "./forge-history-item";

type ForgeHistoryProps = { forgedBoosts: ProcessedBoost[] };

export const ForgeHistory = ({ forgedBoosts }: ForgeHistoryProps) => {
  return (
    <div className="h-full bg-white p-4 flex flex-col gap-4">
      <Typography variant="h2" affects="removePMargin">
        History
      </Typography>

      <ScrollArea>
        <div className="flex flex-col gap-6 sm:gap-2 pb-16 sm:pb-0">
          {forgedBoosts
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            .slice(0, 10)
            .map((fb) => (
              <ForgeHistoryItem
                key={fb.id}
                id={fb.id}
                date={fb.date}
                inputBoosts={fb.inputBoosts}
                outputBoost={fb.outputBoost}
                emojiReward={fb.emojiReward}
              />
            ))}
        </div>
      </ScrollArea>

      {forgedBoosts.length === 0 && <p>Forged XP boosts will appear here.</p>}
    </div>
  );
};
