import Typography from "../../typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../table";

export const BingoPrizes = () => {
  const prizes = [
    { place: 1, reward: 500 },
    { place: 2, reward: 350 },
    { place: 3, reward: 200 },
    { place: 4, reward: 100 },
  ];

  return (
    <div className="flex flex-col gap-2 bg-white rounded-md border border-slate-200 p-4 w-full h-full">
      <Typography variant="h3">Prizes</Typography>
      <small className="text-gray-500">
        Prizes are rewarded for every team member that has contributed to at
        least one cell.
      </small>

      <Table>
        <TableHeader className="sticky top-0 bg-white z-50">
          <TableRow>
            <TableHead className="w-[20%]">Place</TableHead>
            <TableHead className="w-[80%]">Prize</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {prizes.map((item, index) => (
            <TableRow key={item.place + item.reward}>
              <TableCell>
                {index < 3 ? (
                  <div
                    className={`w-8 h-8 rounded-md flex items-center justify-center text-white font-bold ${
                      index === 0
                        ? "bg-yellow-400"
                        : index === 1
                        ? "bg-gray-400"
                        : "bg-orange-400"
                    }`}
                  >
                    {index + 1}
                  </div>
                ) : (
                  <div className="w-8 h-8 rounded-md flex items-center justify-center text-black font-bold bg-gray-100">
                    {index + 1}+
                  </div>
                )}
              </TableCell>

              <TableCell>
                <p>{item.reward} EXP</p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
