import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { MoreHorizontal, PlusIcon, Trash2, UserPlus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { createTeam } from "../../../data/company/create-team";
import { deleteTeam } from "../../../data/company/delete-team";
import { Team } from "../../../data/company/get-company";
import { joinTeam } from "../../../data/company/join-team";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../dialog";
import { Input } from "../input";
import { Label } from "../label";
import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip";

type TeamListProps = {
  teams: Team[];
  refreshCompanyData: () => void;
};

export default function TeamList({ teams, refreshCompanyData }: TeamListProps) {
  const navigate = useNavigate();

  const [teamName, setTeamName] = useState("");
  const [teamCodeName, setTeamCodeName] = useState("");

  const userId = useAuthStore((store) => store.userId);
  const companyId = useAuthStore((store) => store.companyId);
  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);
  const setTeamId = useAuthStore((store) => store.setTeamId);

  const onClickCreateTeam = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const trimmedTeamName = teamName.trim();
    const trimmedCodeName = teamCodeName.trim().toUpperCase();

    if (!trimmedTeamName || !trimmedCodeName) {
      toast.error("An error occured", {
        description: "Both team name and team code name are required.",
      });

      event.preventDefault();

      return;
    }

    if (trimmedCodeName.length > 5) {
      toast.error("An error occured", {
        description: "Team codename must not exceed 5 characters.",
      });

      event.preventDefault();

      return;
    }

    await createTeam({
      companyId,
      name: teamName,
      codeName: teamCodeName,
    });

    setTeamName("");
    setTeamCodeName("");

    refreshCompanyData();
  };

  const onClickChangeTeam = async (teamId: string) => {
    await joinTeam({
      companyId,
      teamId,
    });

    setTeamId(teamId);

    refreshCompanyData();
  };

  const onClickDeleteTeam = async (teamId: string) => {
    await deleteTeam({
      companyId,
      teamId,
    });

    refreshCompanyData();
  };

  return (
    <div className="flex flex-col gap-3 w-full bg-white rounded-md border border-slate-200 p-4 mt-4 max-w-96">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-row items-end gap-3">
          <h3 className="text-2xl font-semibold">Teams</h3>
          <span className="text-2xl font-semibold text-blue-500">
            {teams.length}
          </span>
        </div>

        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="icon" disabled={!isCompanyAdmin}>
              <PlusIcon className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Team creation</DialogTitle>
              <DialogDescription>
                Company members will be able to join this team.
              </DialogDescription>
            </DialogHeader>

            <div className="flex flex-col space-y-4">
              <div className="grid gap-2">
                <Label htmlFor="teamName">Team name</Label>
                <Input
                  id="teamName"
                  placeholder="Technical"
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                />
              </div>

              <div className="grid gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label htmlFor="teamCodename">Team short name</Label>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoCircledIcon className="w-4 h-4" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        A short 'code name' for the team, to display in charts.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
                <Input
                  id="teamCodename"
                  placeholder="TECH"
                  value={teamCodeName}
                  className="uppercase"
                  maxLength={5}
                  onChange={(event) =>
                    setTeamCodeName(event.target.value.toUpperCase())
                  }
                />
              </div>
            </div>

            <DialogFooter className="mt-6">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="default"
                  onClick={(event) => onClickCreateTeam(event)}
                  className="w-full"
                >
                  Create
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {/* Teams Table */}
      <div className="w-full h-full relative overflow-auto">
        <Table>
          <TableHeader className="sticky top-0 bg-white z-50">
            <TableRow>
              <TableHead className="w-[40%]">Name</TableHead>
              <TableHead className="w-[50%]">Members</TableHead>
              <TableHead className="w-[10%]"></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {teams.map((team) => (
              <TableRow key={team.id}>
                <TableCell>
                  <div className="font-medium">{team.name}</div>
                  <div className="text-sm text-gray-500 uppercase">
                    {team.codeName.slice(0, 5)}
                  </div>
                </TableCell>

                <TableCell>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex flex-row items-center">
                        <div className="flex flex-row -space-x-2 mr-2">
                          {team.members.slice(0, 4).map((member) => (
                            <Avatar
                              key={member.id}
                              className="h-8 w-8 border-2 border-white hover:cursor-pointer"
                              onClick={() => navigate(`/profile/${member.id}`)}
                            >
                              <AvatarImage
                                src={member.avatarUrl}
                                alt={`${member.firstName} ${member.lastName}`}
                              />
                              <AvatarFallback>
                                {getFallbackName(
                                  member.firstName,
                                  member.lastName
                                )}
                              </AvatarFallback>
                            </Avatar>
                          ))}
                        </div>
                        {team.members.length > 4 && (
                          <span className="text-sm text-gray-500">
                            +{team.members.length - 4}
                          </span>
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className="bg-white text-black border border-gray-200">
                      <p className="font-semibold">Team Members</p>
                      <ul className="list-disc pl-4">
                        {team.members.map((member) => (
                          <li
                            key={member.id}
                          >{`${member.firstName} ${member.lastName}`}</li>
                        ))}
                      </ul>
                    </TooltipContent>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="w-full sm:w-auto"
                      >
                        <MoreHorizontal className="h-4 w-4" />
                        <span className="sr-only">Actions</span>
                      </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        className="flex items-center"
                        disabled={
                          !!team.members.find((member) => member.id === userId)
                        }
                        onClick={() => onClickChangeTeam(team.id)}
                      >
                        <UserPlus className="mr-2 h-4 w-4" />
                        <span>Join</span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        disabled={!isCompanyAdmin}
                        className="flex items-center text-red-600"
                        onClick={() => onClickDeleteTeam(team.id)}
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Delete</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
