import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { CheckIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { User, getUserById } from "../../../data/auth/get-user";
import { Team } from "../../../data/company/get-company";
import { updateUser } from "../../../data/settings/update-user";
import { getFallbackName } from "../../../lib/name-utils";
import { cn } from "../../../lib/utils";
import { useAuthStore } from "../../../stores/auth.store";
import { LogoutDialog } from "../../dialogs/logout-dialog";
import Typography from "../../typography";
import { Button } from "../button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../command";
import { Input } from "../input";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Separator } from "../separator";
import { Switch } from "../switch";
import { EmailVerification } from "./email-verification";

type ProfileTabProps = { teams: Team[] };

export const ProfileTab = (props: ProfileTabProps) => {
  const userId = useAuthStore((store) => store.userId);
  const companyId = useAuthStore((store) => store.companyId);
  const setIsVerified = useAuthStore((store) => store.setIsVerified);
  const setTeamId = useAuthStore((store) => store.setTeamId);

  const [user, setUser] = useState<User>();

  const [teamSelectOpen, setTeamSelectOpen] = useState(false);
  const [teamSelectValue, setTeamSelectValue] = useState("");
  const [teamSelectId, setTeamSelectId] = useState("");

  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [weight, setWeight] = useState<number>(0);
  const [isEmailSubscribed, setIsEmailSubscribed] = useState<boolean>(true);

  const [avatarSrc, setAvatarSrc] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<File>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);

      const data = await getUserById({
        userId,
      });

      setUser(data);

      setIsVerified(data.isVerified!);

      setEmail(data.email!);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setWeight(data.weight || 0);
      setIsEmailSubscribed(data.isEmailSubscribed ?? true);

      setAvatarSrc(data.avatarUrl);
      setTeamSelectValue(data.team?.codeName || "");
      setTeamSelectId(data.team?.id || "");

      setIsLoading(false);
    };

    loadUser();
  }, [userId]);

  useEffect(() => {
    if (weight > 200) {
      setWeight(200);
    }
  }, [weight]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setAvatarSrc(imageURL);
      setAvatarFile(file);
    }
  };

  const onClickSave = async () => {
    setIsLoading(true);

    await updateUser({
      userId,
      firstName,
      lastName,
      teamId: teamSelectId,
      avatarFile,
      isEmailSubscribed,
      weight,
    });

    setTeamId(teamSelectId);

    setIsLoading(false);
    toast.success("Successfully saved");
  };

  return (
    <div className="w-full h-full bg-white rounded-md border border-slate-200 p-4 flex flex-col gap-4 pb-4">
      <div>
        <Typography variant="h3">Profile</Typography>
        <Typography variant="p" affects="muted" className="text-sm">
          General settings regarding your own profile.
        </Typography>
      </div>

      <Separator />

      <div className="flex flex-col md:flex-row gap-4 w-full">
        <div className="flex flex-col gap-1">
          <Label className="flex flex-col gap-1">
            Avatar
            <Avatar
              size="large"
              affects="noborder"
              className="hover:border-gray-400 hover:border hover:border-dotted hover:cursor-pointer"
            >
              <AvatarImage src={avatarSrc} />
              <AvatarFallback>
                {user ? getFallbackName(user.firstName, user.lastName) : ""}
              </AvatarFallback>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </Avatar>
          </Label>
        </div>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col gap-1 w-full md:max-w-96">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="text"
                defaultValue={email}
                disabled={true}
              />
            </div>
            <div className="flex flex-col gap-1 w-full md:max-w-96">
              <Label htmlFor="team">Team</Label>

              <Popover open={teamSelectOpen} onOpenChange={setTeamSelectOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className="justify-between"
                    id="team"
                    disabled={!companyId}
                  >
                    <Typography variant="p" className="text-sm font-normal">
                      {teamSelectValue
                        ? props.teams.find(
                            (team) => team.codeName === teamSelectValue
                          )?.codeName
                        : "Select team..."}
                    </Typography>
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search team..."
                      className="h-9"
                    />

                    <CommandList>
                      <CommandEmpty>No team found.</CommandEmpty>

                      <CommandGroup>
                        {props.teams.map((team) => (
                          <CommandItem
                            key={team.id}
                            value={team.codeName}
                            keywords={[team.codeName, team.name]}
                            onSelect={(currentValue) => {
                              setTeamSelectValue(currentValue);
                              setTeamSelectId(
                                props.teams.find(
                                  (team) => team.codeName === currentValue
                                )?.id || ""
                              );
                              setTeamSelectOpen(false);
                            }}
                          >
                            {team.name} ({team.codeName})
                            <CheckIcon
                              className={cn(
                                "ml-auto h-4 w-4",
                                teamSelectValue === team.codeName
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex flex-col gap-1 w-full md:max-w-96">
              <Label htmlFor="firstName">Weight</Label>
              <Input
                id="weight"
                type="number"
                defaultValue={0}
                min={0}
                max={200}
                placeholder="Unspecified"
                value={weight === 0 ? "Unspecified" : weight}
                onChange={(event) =>
                  setWeight(parseInt(event.target.value) || 0)
                }
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="flex flex-col gap-1 w-full md:max-w-96">
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                type="text"
                defaultValue={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>

            <div className="flex flex-col gap-1 w-full md:max-w-96">
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                type="text"
                defaultValue={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 w-full">
        <EmailVerification />

        <div className="flex flex-row w-full items-center justify-between rounded-md border p-3 shadow-sm">
          <div className="space-y-0.5">
            <Label className="text-lg font-semibold">Email Notifications</Label>
            <p className="text-sm text-gray-600">
              Receive emails about new activities, features, and more.
            </p>
          </div>

          <Switch
            onCheckedChange={setIsEmailSubscribed}
            checked={user?.isVerified && isEmailSubscribed}
            disabled={!user?.isVerified}
          />
        </div>
      </div>

      <Separator />

      <div className="w-full flex justify-between">
        <LogoutDialog />

        <Button className="px-6" onClick={onClickSave} disabled={isLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};
