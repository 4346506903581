import {
  BingoCard as BingoCardType,
  BingoChallenge,
} from "../../../data/minigames/bingo/types";
import { BingoCell } from "./BingoCell";

interface BingoProps {
  bingoCard: BingoCardType;
  onChallengeClick: (challenge: BingoChallenge) => void;
}

export const BingoCard = ({ bingoCard, onChallengeClick }: BingoProps) => {
  return (
    <div className="grid grid-cols-5 gap-1 h-full">
      {bingoCard.challenges.map((challenge) => (
        <div
          className="w-full h-full"
          onClick={() =>
            bingoCard.currentWeek >= challenge.week &&
            onChallengeClick(challenge)
          }
          key={challenge.id}
        >
          <BingoCell
            challenge={challenge}
            isRevealed={challenge.week <= bingoCard.currentWeek}
            currentWeek={bingoCard.currentWeek}
          />
        </div>
      ))}
    </div>
  );
};
